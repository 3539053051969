
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { useIsMobile } from '@/hooks/use-mobile';

const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={cn(
        "fixed top-0 left-0 right-0 z-50 transition-all duration-300 py-4 px-4 md:px-8",
        scrolled ? "bg-white/80 backdrop-blur-md shadow-sm" : "bg-transparent"
      )}
    >
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <img 
            src="/lovable-uploads/00525eb1-861d-450e-aded-1102c0cae4e7.png" 
            alt="Cutshort" 
            className="h-8 md:h-10 w-auto" 
            width="120"
            height="40"
            loading="eager"
          />
        </div>
        
        <nav className="hidden md:flex items-center space-x-8">
          <a href="#how-it-works" className="text-brand-darkgrey hover:text-brand-blue transition-colors">
            How It Works
          </a>
          <a href="#plans" className="text-brand-darkgrey hover:text-brand-blue transition-colors">
            Pricing
          </a>
          <a href="https://cutshort.io/hiring" target="_blank" rel="noopener noreferrer" className="text-brand-darkgrey hover:text-brand-blue transition-colors">
            Hire on your own
          </a>
        </nav>
        
        {!isMobile && (
          <a 
            href="https://cutshort.io/a/generic-emp-form" 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-brand-blue text-white px-6 py-2 rounded-lg shadow-md transition-all hover:shadow-lg hover:translate-y-[-1px]"
          >
            Get a callback
          </a>
        )}
      </div>
    </header>
  );
};

export default NavBar;
