
import React, { useEffect, useState, lazy, Suspense } from 'react';
import NavBar from '@/components/NavBar';
import Hero from '@/components/Hero';

// Lazily load non-critical components
const HowItWorks = lazy(() => import('@/components/HowItWorks'));
const CustomerSuccessStories = lazy(() => import('@/components/CustomerSuccessStories'));
const VariantTable = lazy(() => import('@/components/VariantTable'));
const ATSIntegrations = lazy(() => import('@/components/ATSIntegrations'));
const FAQ = lazy(() => import('@/components/FAQ'));
const Footer = lazy(() => import('@/components/Footer'));

// Loading fallback with reduced size
const LoadingFallback = () => (
  <div className="flex justify-center items-center py-6">
    <div className="animate-pulse h-10 w-full max-w-xl bg-gray-100 rounded-lg"></div>
  </div>
);

const Index = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Set page title
    document.title = "Boost your talent pipeline at 3.5% fees";
    
    // Mark as loaded immediately for critical content
    setIsLoaded(true);
    
    // Preload important images
    const preloadImages = [
      "/lovable-uploads/00525eb1-861d-450e-aded-1102c0cae4e7.png", // Logo
      "/lovable-uploads/4b3bea26-06d2-4efd-a04b-f6046d794008.png", // Noel avatar
    ];
    
    preloadImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <div className={`min-h-screen ${isLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
      <NavBar />
      <main>
        <Hero />
        
        {/* Use IntersectionObserver to load components only when they're about to be visible */}
        <Suspense fallback={<LoadingFallback />}>
          <HowItWorks />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <CustomerSuccessStories />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <VariantTable />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <ATSIntegrations />
        </Suspense>
        
        <Suspense fallback={<LoadingFallback />}>
          <FAQ />
        </Suspense>
      </main>
      
      <Suspense fallback={<LoadingFallback />}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Index;
