
import React, { useEffect, useRef } from 'react';

const Hero = () => {
  const heroRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100');
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  return (
    <section 
      ref={heroRef}
      className="min-h-screen pt-24 pb-16 flex flex-col justify-center opacity-0 transition-opacity duration-700"
      style={{ 
        background: "linear-gradient(180deg, rgba(240,241,247,0.8) 0%, rgba(255,255,255,1) 100%)"
      }}
    >
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-10 md:gap-16">
          <div className="flex-1 max-w-2xl">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-brand-darkgrey mb-6">
              Boost your talent pipeline. Zero upfront price.
            </h1>
            
            <p className="text-xl text-brand-grey mb-8">
              Give your roles to Cutshort and we will start sending you the perfect candidates within 24 hours. Pay 3.5% of CTC only when you hire.
            </p>
            
            <div className="flex flex-col sm:flex-row gap-4">
              <a 
                href="https://cutshort.io/a/generic-emp-form" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="bg-brand-blue hover:bg-brand-blue/90 text-white px-8 py-4 rounded-xl text-lg font-medium transition-all shadow-md hover:shadow-xl hover:translate-y-[-2px] inline-block text-center"
              >
                Start getting candidates
              </a>
              <a 
                href="https://cutshort.io/a/generic-emp-form" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="border border-brand-blue/30 hover:border-brand-blue/60 text-brand-blue px-8 py-4 rounded-xl text-lg font-medium transition-all hover:bg-brand-blue/5 inline-block text-center"
              >
                Get a callback
              </a>
            </div>
            
            <div className="mt-12">
              <h3 className="text-sm font-medium text-brand-darkgrey mb-4">Powered tech talent sourcing for...</h3>
              <div className="flex flex-wrap items-center gap-8 mt-2">
                <img 
                  src="/lovable-uploads/7d1ccd03-2690-4a11-9f91-d42bb6d5a308.png" 
                  alt="Fractal" 
                  className="h-8 opacity-80 hover:opacity-100 transition-opacity"
                  width="80"
                  height="32"
                  loading="lazy"
                />
                <img 
                  src="/lovable-uploads/68024710-3e67-4a2b-866d-21d0cd49b4b0.png" 
                  alt="Shiprocket" 
                  className="h-8 opacity-80 hover:opacity-100 transition-opacity"
                  width="100" 
                  height="32"
                  loading="lazy"
                />
                <img 
                  src="/lovable-uploads/32334656-9272-41cd-b728-c0ce02ee72f0.png" 
                  alt="Sprinto" 
                  className="h-8 opacity-80 hover:opacity-100 transition-opacity"
                  width="80"
                  height="32"
                  loading="lazy"
                />
                <img 
                  src="/lovable-uploads/f0e559de-57be-429d-8fc3-fdb35842aa81.png" 
                  alt="StepSecurity" 
                  className="h-8 opacity-80 hover:opacity-100 transition-opacity"
                  width="100"
                  height="32"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          
          <div className="flex-1 w-full max-w-lg">
            <div className="relative">
              <div className="glass-card p-6 rounded-2xl shadow-xl">
                <div className="bg-brand-offwhite p-5 rounded-xl mb-4">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="h-10 w-10 rounded-full overflow-hidden">
                      <img 
                        src="/lovable-uploads/4b3bea26-06d2-4efd-a04b-f6046d794008.png" 
                        alt="Noel" 
                        className="h-full w-full object-cover"
                        width="40"
                        height="40"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h3 className="font-semibold text-brand-darkgrey">Noel from Cutshort</h3>
                      <p className="text-xs text-brand-grey">Just now</p>
                    </div>
                  </div>
                  <p className="text-sm text-brand-darkgrey">
                    11 candidates found for Senior React Developer role and have been added to your ATS
                  </p>
                </div>
                
                <div className="space-y-3">
                  <div className="flex gap-3 items-center">
                    <div className="h-8 w-8 rounded-full bg-brand-blue/20"></div>
                    <div className="flex-1 h-12 bg-brand-offwhite rounded-lg"></div>
                  </div>
                  <div className="flex gap-3 items-center">
                    <div className="h-8 w-8 rounded-full bg-brand-blue/20"></div>
                    <div className="flex-1 h-12 bg-brand-offwhite rounded-lg"></div>
                  </div>
                  <div className="flex gap-3 items-center">
                    <div className="h-8 w-8 rounded-full bg-brand-blue/20"></div>
                    <div className="flex-1 h-12 bg-brand-offwhite rounded-lg"></div>
                  </div>
                </div>
                
                <div className="mt-5 flex justify-between">
                  <button className="bg-brand-blue text-white px-4 py-2 rounded-lg text-sm">
                    View Candidates
                  </button>
                  <button className="border border-brand-grey/30 text-brand-grey px-4 py-2 rounded-lg text-sm">
                    Request More
                  </button>
                </div>
              </div>
              
              <div className="absolute -bottom-6 -left-6 h-24 w-24 rounded-full bg-brand-yellow/30 blur-xl"></div>
              <div className="absolute -top-6 -right-6 h-16 w-16 rounded-full bg-brand-blue/20 blur-xl"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
